import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import {fetchsingleProductsdata } from '../data/Allapidata';

function QuantityInput() {
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = () => {
    if (quantity < 100) {
      setQuantity(quantity + 1);
    }
  };
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <div className="main_div_flx_add">
      <div className="quantity products_qty_input">
        <button className="minus" aria-label="Decrease" onClick={handleDecrease}>-</button>
        <input type="number" className="input-box" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value) || 1)} min={1} max={100} />
        <button className="plus" aria-label="Increase" onClick={handleIncrease}>+</button>
      </div>
      <div className="addtocart_div">
        <Link to="">Add To Cart</Link>
      </div>
    </div>
  );
}

const ChalkmakingmachineDetails = () => {
  const {category, id,productid } = useParams(); // Get the productid from the URL

  const [productsData, setProductsData] = useState([]);
  const [productsUrl, setProductsUrl] = useState('');
  useEffect(() => {
    const productsDecData = async () => {
      try {
        const res = await fetchsingleProductsdata(productid);
        if (res?.success === true) {
          setProductsData(res?.data);
          setProductsUrl(res?.URL);
        }
      } catch (error) {
        console.log(error);
      }
    };
    productsDecData();
  }, [productid]);



  return (
    <>
      {/* Page Title Area Start */}
      <section className="inner_page_tisection_bred">
        <div className="container">
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active"><Link to="/">Home</Link></li> -
                  <li><Link to={`../${category}/${productid}`}>{category}</Link></li> -
                  <li><Link to="javascript:void(0)">{id}</Link></li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* Page Title Area End */}

      {/* Products Details Section Start */}
      <section className="products_details_section">
        <div className="container">
          <div className="row">
            {/* col start */}
            {productsData.map((product, index) => (
              <ProductDetailItems key={index} productsData={product} productsUrl={productsUrl} />
            ))}
            
            {/* col end */}
          </div>
        </div>
      </section>
      {/* Products Details Section End */}
    </>
  );
};

export default ChalkmakingmachineDetails;

function ProductDetailItems({ productsData, productsUrl }) {
  const discountPercentage = ((productsData?.mrp - productsData?.sellprice) / productsData?.mrp) * 100;
  return (
    <>
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="products_dt_img_maindiv">
          <div className="img_box_pro_dt">
            <Link to=""><img src={`${productsUrl}${productsData?.image}`} alt="priyavindustries" className='img-fluid' /></Link>
          </div>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-7 col-md-6 col-sm-12">
        <div className="products_dt_txt_div">
          <h2>{productsData?.name}</h2>
          <div className="review-box">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star-half-o"></i>
            <span>(4.5 Star Ratings)</span>
          </div>

          <p className="products_dt_price">
            <b className="productsnetprice">₹ {productsData?.sellprice} /- </b> 
            <span className="productsdissprice"> ₹ {productsData?.mrp} /- </span>
          </p>
          <h6 className="products_dt_disscount">
          <b className="product_dtoffdiv">{discountPercentage?.toFixed(2)}% Off</b>
          <span className="products_dtfreedlv">{productsData?.freedlv ? 'Free Delivery' : ''}</span>
          </h6>
          {/* Quantity Input */}
          <QuantityInput />

          <div className="delivery_option_div">
            <h4><span className='fa fa-truck'></span> Delivery Options</h4>
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Enter Pin Code" />
              <button className="btn btn-outline-secondary" type="button" id="button-addon2">Check</button>
            </div>
            <p>Please enter PIN code to check delivery time & Pay on Delivery Availability</p>
          </div>
        </div>
      </div>
      {/* col end */}
    </>
  );
}
