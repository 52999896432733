import React, { useEffect, useState } from 'react';
import { options } from '../data/Owlcarouseloptions';
import { Link } from "react-router-dom";
import Mobileproductsdata from './Mobileproductsdata';
import { fetchcatdata, fetchsingleCategorydata, fetchsliderdesktopdata } from '../data/Allapidata';
import Slider from "react-slick";

const Home = () => {
  const [sliderdesktop, setsliderdesktop] = useState([]);
  const [sliderBaseurl, setsliderBaseurl] = useState('');

  useEffect(() => {
    const deskslider = async () => {
      try {
        const res = await fetchsliderdesktopdata();
        if (res?.success === true) {
          setsliderdesktop(res?.data);
          setsliderBaseurl(res?.URL);
        }

      } catch (error) {
        console.log(error);
      }
    };
    deskslider();
  }, []);

  // category api start=======
  const [categories, setCategories] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState({});
  const [CategoryUrl, setCategoryUrl] = useState({});
  const [CategoryUrll, setCategoryUrll] = useState({});
  console.warn(CategoryUrll);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const res = await fetchcatdata();
        if (res?.success) {
          const categoryData = res.data;
          setCategoryUrl(res?.URL)
          setCategories(categoryData);

          const productsData = {};
          for (const category of categoryData) {
            try {
              const productRes = await fetchsingleCategorydata(category.id);
              if (productRes?.success) {
                setCategoryUrll(productRes?.URL);
                productsData[category.id] = productRes.data;
              }
            } catch (error) {
              console.log(`Error fetching products for category ${category.id}:`, error);
            }
          }
          setProductsByCategory(productsData);
        }
      } catch (error) {
        console.log('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoriesAndProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  // category api end=======

  return (
    <>
      {/* slider section start */}
      <section className="slider_section">
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            {sliderdesktop?.map((ele, index) => (
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
                aria-current={index === 0 ? 'true' : 'false'}
                aria-label={`Slide ${index + 1}`}
                key={ele?.id}
              />
            ))}
          </div>
          {/*  */}
          <div className="carousel-inner">
            {/* item */}
            {sliderdesktop?.map((ele, index) => {
              return (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={ele?.id}>
                  <img src={`${sliderBaseurl}${ele?.image}`} className="d-block w-100" alt="..." />
                </div>
              );
            })}
            {/* end item */}
          </div>
          {/*  */}
          <div className="btn_left_right">
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <i className='bx bx-left-arrow'></i>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <i className='bx bx-right-arrow'></i>
            </button>
          </div>
        </div>
      </section>
      {/* slider section end */}

      {/* wrapepr section  */}
      <div className="wrapper_div">
        {/* products section start */}
        <section className="products_section">
          <div className="container">
            {categories?.slice(0, 5)?.map((category) => (
              <div key={category.id}>
                <div className="products_title_div">
                  <h1><Link to={`${category?.name?.replace(/\s/g, '-')}/${category?.id}`}>{category.name}</Link></h1>
                </div>
                <div className="row align-items-center">
                
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <Slider {...options}>
                      {productsByCategory[category.id]?.map((product) =>{
                        const discountPercentage = ((product?.mrp - product?.sellprice) / product?.mrp) * 100;
                        return(
                          <div className='items' key={product.id}>
                          <Link to={`${product?.category?.replace(/\s/g, '-')}/${product?.name?.replace(/\s/g, '-')}/${product?.id}`}>
                            <div className="products_items_main_div_slider">
                              <div className="products_itmes_img_sldr">
                                <img src={`${CategoryUrll}${product.image}`} alt={product.name} className='img-fluid' />
                              </div>
                              <div className="products_name_div">
                                <h3>{product.name}</h3>
                                <p><b>₹ {product.sellprice} /-</b> <span> ₹ {product.mrp} /-</span></p>
                                <h6>{product?.freedlv ? 'Free Delivery' : ''}</h6>
                              </div>
                            </div>
                          </Link>
                        </div>
                        )
                      } )}
                    </Slider>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        {/* products section end */}
      </div>
      {/* wrapepr section end */}

      {/*========================== mobile products data=========================  */}
      <Mobileproductsdata />
      {/*========================== mobile products data=========================  */}

      {/* supports section start  */}
      <section className="support_section">
        <div className="container">
          <div className="row">
            {/* col start  */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="support_txt_div">
                <div className="support_icons_div">
                  <img src="../images/icon01.png" alt="priyavindustries" />
                </div>
                <h5>Free Delivery</h5>
                <p>Free delivery on all products</p>
              </div>
            </div>
            {/* col end */}

            {/* col start  */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="support_txt_div support_txt_div2">
                <div className="support_icons_div support_icons_div2">
                  <img src="../images/icon02.png" alt="priyavindustries" />
                </div>
                <h5>3 days Return</h5>
                <p>Return Option available</p>
              </div>
            </div>
            {/* col end */}

            {/* col start  */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="support_txt_div">
                <div className="support_icons_div">
                  <img src="../images/icon03.png" alt="priyavindustries" />
                </div>
                <h5>Quality Products</h5>
                <p>Selling Quality Products</p>
              </div>
            </div>
            {/* col end */}

            {/* col start  */}
            <div className="col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="support_txt_div support_txt_div2">
                <div className="support_icons_div support_icons_div2">
                  <img src="../images/icon04.png" alt="priyavindustries" />
                </div>
                <h5>24/7 Support</h5>
                <p>Full customer support</p>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* supports section end */}
    </>
  )
}

export default Home;
