import React from 'react'

const Modalpage = () => {
  return (
    <>
    {/* Modal */}
<div className="search_bar_divmd">
  <div className="modal fade" id="enqformopen" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="enqformopenLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body pop_modal">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="poppup_searchbar">
                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>  */}
                <form method="GET" autoComplete="off" action="search">
                  <div className="input-group">                               
                    <input type="text" className="form-control" name="key" list="browsers" id="search" placeholder="Search Here..." required />
                    <button className="input-group-text bx bx-search-alt" id="searchbutton" type="submit" name="submitsearch" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* end */}
    </>
  )
}

export default Modalpage
