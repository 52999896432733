import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { fetchaboutdata } from '../data/Allapidata';


const Aboutus = () => {

  const [aboutData, setAboutdata] = useState();
  const [aboutTitle, setaboutTitle] = useState();

  
  useEffect(() => {
    const abouttxtData = async () => {
      try {
        const res = await fetchaboutdata();
        if (res?.success === true) {
          setAboutdata(res?.data);
          setaboutTitle(res?.data[0]?.title);
        }
      } catch (error) {
        console.log(error);
      }
    };
    abouttxtData();
  }, []);

  return (
    <>

    {/*  Page Title Area Start  */}

     <section className="inner_page_tisection_bred">
  <div className="container">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)">{aboutTitle}</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
 
   
    {/*Page Title Area End  */}

   {/* About Us Section */}
   <section className="aboutus_section">
        <div className="container">
          <div className="row">
            {/* col start */}
{aboutData?.map((ele) =>{
return(
  <div className="col-lg-12" key={ele?.id}>
  <div className="aboutus_txt_div">
  <h2>{ele?.title}</h2>
  <p>{ele?.desc}</p>
  </div>
</div>
)
})}
   {/* col end */}
   </div>
        </div>
      </section>
      {/* About Us Section End */}
    </>
  );
}

export default Aboutus;
