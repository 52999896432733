import React from 'react'
import { Link } from "react-router-dom";

const Contactus = () => {
  return (
    <>
    
    {/*  Page Title Area Start  */}

    <section className="inner_page_tisection_bred">
  <div className="container">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)"> Company Profile</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
    {/*Page Title Area End  */}


{/* ===================contact us page section start ============== */}
<section className="contact_us_page_section">
  <div className="container">
   
    <div className="row">
      {/* col start  */}
      <div className="col-lg-5 col-md-6 col-sm-12 col-12">
        {/* main div contact page start*/}
        <div className="main_div_contactus_page">
        
          {/* contact us flex div main start*/}
          <div className="contact_us_flx_div_main">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bx bxs-phone-call" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="contact_page_num_div">
                  <b>Mobile No.:</b>
                  <a href="tel:+91 9856622622"> +91 9856622622</a>
                </div>
              </div>
            </div>
          </div>
          {/* contact us flex div main end*/}
          {/* contact us flex div main start*/}
          <div className="contact_us_flx_div_main">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bx bx-mail-send" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="contact_page_num_div">
                  <b>E-Mail ID:</b>
                  <a href="mailto:info@priyavindustries">info@priyavindustries</a>
                </div>
              </div>
            </div>
          </div>
          {/* contact us flex div main end*/}
          {/* contact us flex div main start*/}
          <div className="contact_us_flx_div_main">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bx bxs-map-pin" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="contact_page_num_div">
                  <b>Address:</b>
                  <p>Naharpur Car Market, Near G Block, Rohini, Sector -7, Delhi - 110085 (INDIA)</p>
                </div>
              </div>
            </div>
          </div>
          {/* contact us flex div main end*/}
        </div>
        {/* main div contact page end*/}
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-7 col-md-6 col-sm-12 col-12">
        <div className="contact_page_form">
        <h2>Enquiry Now</h2>
          <form method="POST" className="contact-form" autoComplete="off" onsubmit="return submitUserForm();">
            {/* row nested start */}
            <div className="row">
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-user" />
                  <input type="text" name="name" className="form-control" placeholder="Name*" required minLength={3} oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*?)\..*/g, '$1')" />
                </div>
              </div>
              {/* col nested end */}
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-user" />
                  <input type="text" name="last" className="form-control" placeholder="Surname*" required minLength={3} oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*?)\..*/g, '$1')" />
                </div>
              </div>
              {/* col nested end */}
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-envelope" />
                  <input type="email" className="form-control" name="email" placeholder="Email Id*" minLength={11} required />
                </div>
              </div>
              {/* col nested end */}
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-phone" />
                  <input type="tel" className="form-control" name="phone" id="phone" placeholder="Mobile No." required />
                </div>
                <div id="phoneval22" />
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-commenting" />
                  <textarea name="message" className="form-control" placeholder="Write Your Requirement*" />
                </div>
                <div id="warden_id" />
              </div>
              {/* col nested end */}
              <div className="g-recaptcha " data-sitekey="6LdXvXMpAAAAAPr-OXmelKI5WWojKH17ukTBQUDn" data-callback="verifyCaptcha"> </div>   
              <div id="g-recaptcha-error" />
              {/* col nested start */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                <div className="input_div">
                  <div className="form-group">
                    <center><input type="submit" name="SubmitEmail" Value="Submit Request" className="btn" /></center>
                  </div>
                </div>
              </div>
            </div>
            {/* col nested end */}
          </form></div>
        {/* row nested end */}
      </div>
    </div>
    {/* col end */}
    <br />
    <div className="col-lg-12">
    <p><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13997.705384145303!2d77.099611!3d28.706802000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d07721e41780f%3A0x35f4d4bacdb2fba5!2sPRIYAV%20INDUSTRIES!5e0!3m2!1sen!2sin!4v1714993323275!5m2!1sen!2sin" width="100%" height={350} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /></p>

    </div>
  </div>
</section>
{/* ===================contact us page section end ============== */}


    </>
  )
}

export default Contactus
