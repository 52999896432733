// category api start=======
export const fetchcatdata = async () =>{
   try {
    const res = await fetch ('https://demo.shreejeepools.com/admin/api/category-api.php',{
        method:"Get",
        headers:{
            "Content-Type":"application/json",
        }
    })
        return await res.json();
  
   } catch (error) {
    console.log(error);
   }
}
// category api end=======

// about us api start=======
export const fetchaboutdata = async () =>{
    try {
        const res = await fetch ('https://demo.shreejeepools.com/admin/api/aboutus-api.php',{
            method:"Get",
            headers:{
                "Content-Type":"application/json",
            }
        })
            return await res.json();
    } catch (error) {
       console.log(error) ;
    }
}
// about us api end=======


// Terms & Conditions and Refund & Cancellation api start=======
export const fetchtrmcondidata = async () =>{
  try {
    const res = await fetch ('https://demo.shreejeepools.com/admin/api/privacy-policy-api.php',{
        method:"Get",
        headers:{
            "Content-Type":"application/json",
        }
    })
        return await res.json();
  
  } catch (error) {
    console.log(error);
  }
}
// Terms & Conditions and Refund & Cancellation api end=======


// hero slider desktop api start=======
export const fetchsliderdesktopdata = async () =>{
    try {
        const res = await fetch ('https://demo.shreejeepools.com/admin/api/slider-fetch-api.php',{
            method:"Get",
            headers:{
                "Content-Type":"application/json",
            }
        })
            return await res.json();
      
    } catch (error) {
        console.log(error); 
    }
}
// hero slider desktop api end=======

// Products api end=======
export const fetchsingleCategorydata  = async(data) =>{
   try {
    const res = await fetch(`https://demo.shreejeepools.com/admin/api/single-category-product-api.php?cid=${data}`,{
        method:"Get",
        headers:{
            "Content-Type":"application/json",
        }
    })
    return await res.json();
   } catch (error) {
    console.log(error); 
   }
}
// Products api end=======


// Products api end=======
export const fetchsingleProductsdata  = async(data) =>{
    try {
     const res = await fetch(`https://demo.shreejeepools.com/admin/api/single-product-api.php?pid=${data}`,{
         method:"Get",
         headers:{
             "Content-Type":"application/json",
         }
     })
     return await res.json();
    } catch (error) {
     console.log(error); 
    }
 }
 // Products api end=======

 
// Products api end=======
export const userProfile  = async() =>{
    try {
     const res = await fetch(`https://demo.shreejeepools.com/admin/api/user-profile-api.php?userid=2`,{
         method:"Get",
         headers:{
             "Content-Type":"application/json",
         }
     })
     return await res.json();
    } catch (error) {
     console.log(error); 
    }
 }
 // Products api end=======


