import React, { useEffect, useState } from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import Modalpage from './Modalpage';
import Sidenavbar from './Sidenavbar';
import { fetchcatdata } from '../data/Allapidata';

const Header = () => {

  // category api start=======

  const [cateData, setCateData] = useState([]);
  useEffect(() => {
    const categoryData = async () => {
      try {
        const res = await fetchcatdata();
        if (res?.success === true) {
          setCateData(res?.data);
        }
      } catch (error) {
        console.log('Error fetching category data:', error); // Update this line
      }
    };
    categoryData();
  }, []);
  // category api end=======

  return (
    <>
    
      {/* top bar section start */}
      <section className="topbar_section">
        <div className="container">
          <div className="row">
            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="left_topbar_div">
                <Link to="tel:+91 9856622622"><span className='fa fa-phone'></span> +91 9856622622</Link>
                <Link to="mailto:info@priyavindustries"><span className='fa fa-envelope'></span> info@priyavindustries</Link>
              </div>
            </div>
            {/* col end */}

            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="right_topbar_div">
                <Link to="javacript:void(0);"><i className='fa fa-truck'></i> Free Delivery</Link>
                <Link to="javacript:void(0);"><i className='fa fa-globe'></i> Returns Policy</Link>

                <b>Follow Us:</b>
                <Link to="" className='fa fa-facebook'></Link>
                <Link to="" className='fa fa-instagram'></Link>
                <Link to="" className='fa fa-youtube'></Link>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* top bar section end */}

      {/* logo section start */}
      <section className="logo_section">
        <div className="container">
          <div className="row align-items-center">
            {/* col start  */}
            <div className="col-lg-12 col-md-12 col-12">
              <div className="logo_main_div">
                <div className="logo_flex_div">
                  {/*logo div */}
                  <div className="logo_div">
                    <Link to="/"><img src="../logo/logo.png" className='img-fluid' alt="" /></Link>
                  </div>
                  {/* logo div end */}

                  {/* search div start  */}
                  <div className="search_div_logo">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Search Here..." aria-label="Recipient's username" aria-describedby="button-addon2" />
                      <button className="fa fa-search" type="button" id="button-addon2"></button>
                    </div>
                  </div>
                  {/* search div end */}

                  {/* carts icons logo div */}
                  <div className="logo_div_login">
                    {/* drop down */}
                    <div className="dropdown">
                      <button className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
                        Login  <span className='fa fa-sign-in'></span></button>
                      <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="/login">Sign Up</Link></li>
                        <li><Link className="dropdown-item" to="/myprofile">My Profile</Link></li>
                      </ul>
                    </div>
                    {/* drop down end */}

                    {/* cart div */}
                    <div className="cart_div position-relative">
                      <Link to="/Cart"><span className='fa fa-cart-plus'></span> my cart <span className="badge position-absolute top-0 start-100 translate-middle">4</span></Link>
                    </div>
                    {/* end */}
                  </div>
                  {/* carts icons end */}
                </div>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* logo section end */}

      {/* navbar section start */}
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="../logo/logo.png" className='img-fluid' alt="" />
          </Link>
          {/* phone view icons s */}
          <div className="phone_view_icons_div">
            <span className='phone_icons_nav'>
              <Link to="#enqformopen" data-bs-toggle="modal" className='bx bx-search-alt'></Link>
              <Link to="" className='bx bxs-user-circle'></Link>
              <Link to="" className='bx bx-shopping-bag  position-relative'><span className="badge position-absolute top-1 start-100 translate-middle">4</span></Link>
            </span>
            <Link to="#sidenavbar" className='offcanvas_btn bx bx-menu-alt-right' data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling"></Link>
          </div>
          {/* end */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/about">Company Profile</Link></li>
              {cateData.slice(0, 4).map((item, index) => (
                <li className="nav-item" key={index}><Link className="nav-link" to={`/${item?.name?.replace(/\s/g, '-')}/${item?.id}`}>{item?.name}</Link></li>
              ))}
             
            </ul>
            <ul className="categories_btn">
              <li className="nav-item"><Link className="nav-link" to="/allcategories"> <i className='bx bx-menu-alt-left'></i> All Categories</Link></li>
            </ul>
          </div>
        </div>
      </nav>
      {/* end navr section */}

      {/* search modal start */}
      <Modalpage />
      {/* search modal end */}

      {/* sidenavbar start */}
      <Sidenavbar />
      {/* sidenavbar end */}
    </>
  );
}

export default Header;
