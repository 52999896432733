import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { fetchcatdata } from '../data/Allapidata';



const Allcategories = () => {
// category api start=======
const {id} = useParams();
const [cateData, setCatedata] = useState([]);
const [cateBaseurl, setcateBaseurl] = useState("");

useEffect (()=>{
  const categoryData = async() =>{
    try {
   const res = await fetchcatdata(id);
   if(res?.success===true){
    setCatedata(res?.data);
    setcateBaseurl(res?.URL);
   }
      
    } catch (error) {
      console.log(error)
    }
  }

  categoryData();
},[id])
// category api end=======

  return (
    <>

      {/*  Page Title Area Start  */}

      <section className="inner_page_tisection_bred">
  <div className="container">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)"> categories</Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>

   
    {/*Page Title Area End  */}

    {/* all cate section */}
      <section className="allcategories_section">
        <div className="container">
            <div className="row">
                {/*  */}
                  {cateData.map((item,index)=>{
                    return(
                        <Allcatedata allcateitem={item} baseurl={cateBaseurl} key={index}/> 
                    )
                  }) }
                
           {/* <Allcatedata/> */}
                {/*  */}
            </div>
        </div>
      </section>
      {/* all cate section end */}
    </>
  )
}

export default Allcategories;

function Allcatedata({baseurl, allcateitem}){

    return(
        <>
        {/* col start */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
           <Link to={`../${allcateitem?.name?.replace(/\s/g, '-')}/${allcateitem?.id}`}>
           <div className="all_cate_main_div">
                <div className="all_cateimg_box">
                    <img src={`${baseurl}${allcateitem?.image}`} alt="Priyavindustries" className='img-fluid' />
                </div>
                <div className="all_cate_name_div">
                    <h2>{allcateitem?.name}</h2>
                    <p>{allcateitem?.meta_desc}</p>
                    <span>View More</span>
                </div>
            </div>
           </Link>
        </div>
        {/* col end */}
        </>
    )
    
}
