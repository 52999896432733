import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { fetchtrmcondidata } from '../data/Allapidata';

const Refundcancellation = () => {

  
  const [trmconData, settrmcondata] = useState();
  const [trmcontitleData, settitleondata] = useState();

  
  useEffect(() => {
    const trmcontxtData = async () => {
      try {
        const res = await fetchtrmcondidata();
        if (res?.success === true) {
          settrmcondata(res?.data);
          settitleondata(res?.data[0]?.title_refund);

        }
      } catch (error) {
        console.log(error);
      }
    };
    trmcontxtData();
  }, []);
  return (
    <>
       {/*  Page Title Area Start  */}

    <section className="inner_page_tisection_bred">
  <div className="container">
    <div className="row align-items-center">
      {/* col */}
      <div className="col-lg-12">
        <div className="page_ti_inner_page">
          <ul>
            <li className="active"><Link to="/">Home</Link></li> -
            <li><Link to="javascript:void(0)">{trmcontitleData} </Link></li> 
          </ul>
        </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
    {/*Page Title Area End  */}

{/* Refund & Cancellation  */}

<section className="terms_condition_section">
    <div className="container">
        <div className="row">
            {/* col start */}
            {trmconData?.map((ele) =>{
              return(
                <div className="col-lg-12" key={ele?.id}>
                <div className="term_constion_txt_div">
                   <h2>{ele?.title_refund}</h2>
                    <p>{ele?.Refund}</p>
                    

                </div>
            </div>
              )
            })}
            {/* col end */}
        </div>
    </div>
</section>
{/* Refund & Cancellation  */}

    </>
  )
}

export default Refundcancellation
