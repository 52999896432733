import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { fetchsingleCategorydata } from '../data/Allapidata';

const Chalkmakingmachine = () => {

  const {id, category} = useParams();
  const [productsData, setProductsdata] = useState([]);
  const [productsUrl, setProductsUrl] = useState('');

  useEffect(() => {
    const singleCateDecData = async () => {
      try {
        const res = await fetchsingleCategorydata(id);
        console.table(res?.data);
        if (res?.success === true) {
          setProductsdata(res?.data);
          setProductsUrl(res?.URL);
        }
      } catch (error) {
        console?.log(error);
      }
    };
    singleCateDecData();
  }, [id]);

  return (
    <>
      {/* Page Title Area Start */}
      <section className="inner_page_tisection_bred">
        <div className="container">
          <div className="row align-items-center">
            {/* col */}
            <div className="col-lg-12">
              <div className="page_ti_inner_page">
                <ul>
                  <li className="active"><Link to="/">Home</Link></li> -
                  <li><Link to="javascript:void(0)">{category}</Link></li>
                </ul>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* Page Title Area End */}

      {/* Category Products Section Start */}
      <section className="category_products_section">
        <div className="container">
          <div className="row">
            {/* col start */}
            {productsData?.map((catproduct, index) => (
              <Cateproductsitems cateproitems={catproduct} key={index} productsUrl={productsUrl} />
            ))}
            {/* col end */}
          </div>
        </div>
      </section>
      {/* Category Products Section End */}
    </>
  );
};

export default Chalkmakingmachine;

function Cateproductsitems({ cateproitems, productsUrl }) {
  const discountPercentage = ((cateproitems?.mrp - cateproitems?.sellprice) / cateproitems?.mrp) * 100;

  return (
    <>
      {/* col start */}
      <div className="col-lg-3 col-md-6 col-sm-6 col-6">
        <Link to={`/${cateproitems?.category?.replace(/\s/g, '-')}/${cateproitems?.name?.replace(/\s/g, '-')}/${cateproitems?.id}`}>
          <div className="category_products_main_div">
            <div className="category_products_img_div">
              <img src={`${productsUrl}${cateproitems?.image}`} alt="Priyavindustries" className='img-fluid' />
            </div>
            <div className="category_products_txt_div">
              <h2 className='category_products_name'>{cateproitems?.name}</h2>
              <p className='category_products_price'>
                <b className='netprice'>₹ {cateproitems?.sellprice} /- </b>
                <span className='dissprice'> ₹ {cateproitems?.mrp} /- </span>
              </p>
              <h6 className='category_products_disscount'>
              <b className="product_dtoffdiv">{discountPercentage?.toFixed(2)}% Off</b>
              <span className="products_dtfreedlv">{cateproitems?.freedlv ? 'Free Delivery' : ''}</span>
              </h6>
            </div>
          </div>
        </Link>
      </div>
      {/* col end */}
    </>
  );
}
