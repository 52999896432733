import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { fetchcatdata, fetchsingleCategorydata } from '../data/Allapidata';

const Mobileproductsdata = () => {

  // category api start=======
  const [categories, setCategories] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState({});
  const [CategoryUrll, setCategoryUrll] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const res = await fetchcatdata();
        if (res?.success) {
          const categoryData = res.data;
          setCategories(categoryData);

          const productsData = {};
          for (const category of categoryData) {
            try {
              const productRes = await fetchsingleCategorydata(category.id);
              if (productRes?.success) {
                setCategoryUrll(productRes?.URL);
                productsData[category.id] = productRes.data;
              }
            } catch (error) {
              console.log(`Error fetching products for category ${category.id}:`, error);
            }
          }
          setProductsByCategory(productsData);
        }
      } catch (error) {
        console.log('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoriesAndProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  // category api end=======

  return (
    <>
       {/* Mobile_products section start */}
       <section className="mobile_products_section">
       
         {categories?.map((category, index) =>{
          return(
            <div className="container">
            {/*  */}
           <div className="mobile_products_title">
            <h2>{category?.name}</h2>
           </div>
            {/*  */}
            <div className="row" key={index?.id}>
            {/*  */}
            {productsByCategory[category?.id]?.map((item, index) =>{
              return(
                <MobileproductsItems MobileproductsItems={item} CategoryUrll={CategoryUrll} key={index.id}/>
              )
            })}
              
            {/*  */}
          </div>
          </div>
          )
         })}
        
      </section>
      {/* Mobile_products section end */}
    </>
  )
}

export default Mobileproductsdata;


function MobileproductsItems({MobileproductsItems, CategoryUrll}) {
  const discountPercentage = ((MobileproductsItems?.mrp - MobileproductsItems?.sellprice) / MobileproductsItems?.mrp) * 100;
    return(

 <>
  {/* col start */}
  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
    <Link to={`/${MobileproductsItems?.category?.replace(/\s/g, '-')}/${MobileproductsItems?.name?.replace(/\s/g, '-')}/${MobileproductsItems?.id}`}>
     <div className="mobile_products_main_div">
        <div className="mobile_products_img_div">
          <img src={`${CategoryUrll}${MobileproductsItems?.image}`} alt="" className='img-fluid'/>
        </div>
       <div className="mobile_products_txt_div">
       <h2 className='mobile_products_name'>{MobileproductsItems.name}</h2>
        <p className='mobile_products_price'><b className='netprice'>₹ {MobileproductsItems?.sellprice} /-</b> <span className='dissprice'> ₹ {MobileproductsItems?.mrp} /-</span></p>
        <h6 className='mobile_products_disscount'> <b className='offdiv'>{discountPercentage?.toFixed(2)}% Off</b> <span className='freedlv'> {MobileproductsItems?.freedlv ? 'Free Delivery' : ''}</span></h6>
       </div>
      </div>
     </Link>
    </div>
    {/* col end */}

 </>

    )
}
