import React, { useEffect, useState } from 'react'
import { userProfile } from '../data/Allapidata';
import { useParams } from 'react-router-dom';

const Myprofile = () => {
    const [profile, setprofile] = useState(true);
  // 
  const {userid} = useParams();
  const [userprofiledata, setuserprofiledata] = useState();

  useEffect(() =>{
    const userprofile = async() => {
      try {
        const res = await userProfile();
        console.log(res);
        if(res?.success===true){
          setuserprofiledata(res?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    userprofile();
  },[]);


  return (
    <>
      <section className="myprofile_section">
        <div className="container">
            {/* row start */}
            <div className="row justify-content-center">

                {/* col start */}
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                  {userprofiledata?.map((useritem, index) =>{
                    return(
                      <>
                      <div className="profile_main_div" key={index}>
                        
                        <div className="profile_img_box_main">
                          {/*  */}
                          <div className="profile_flx_div">
                           <img src="./images/profileimg.png" alt="" className='img-fluid'/>
                           <h2>Welcome, {useritem?.name}</h2>
                           </div>
                          {/*  */}

                           {/*  */}
                           <div className="profile_dropbtn">
                          <div className="dropdown">
                        <a className="bx bxs-grid" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false"></a>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" onClick={()=> setprofile(!profile)} href="#"><i class='bx bxs-user'></i> {(profile)? 'Edit Profile' : 'User Details'} </a></li>
                           <li><a className="dropdown-item" href="#"><i class='bx bx-log-out'></i> Logout</a></li>
                        </ul>
                          </div>

                           </div>
                          {/*  */}

                        </div>

                        {(profile)? (<div className="userdetails_div">
                            {/*  */}
                              <div className="userdt_flxdiv">
                                <h3>Name:</h3>
                                <h4>{useritem?.name}</h4>
                              </div>
                            {/*  */}

                            {/*  */}
                            <div className="userdt_flxdiv">
                                <h3>Email ID:</h3>
                                <h4>{useritem?.email}</h4>
                              </div>
                            {/*  */}


                            {/*  */}
                            <div className="userdt_flxdiv">
                                <h3>Mobile No:</h3>
                                <h4>{useritem?.mobile}</h4>
                              </div>
                            {/*  */}

                             {/*  */}
                             <div className="userdt_flxdiv">
                                <h3>Address:</h3>
                                <h4>{useritem?.address}</h4>
                              </div>
                            {/*  */}

                         </div>) :  
                        ( <div className="userdetails_edit_data">
                            {/*  */}
                              <form action="">
                              <div className="row">

                                {/* col start nested */}
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Name</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Email ID</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Mobile No.</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Profile Image</label>
                                <input type="file" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Address</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">City</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">State</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Pin Code</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                 {/* col start nested */}
                                 <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      {/*  */}
                                <label htmlFor="name">Country</label>
                                <input type="text" className='form-control'/>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                                  {/* col start nested */}
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                      {/*  */}
                               <button type='submit' className='btn btn-md'>Save Changes</button>
                                {/*  */}
                                </div>
                                {/* col nested end */}

                              </div>
                              </form>
                            {/*  */}
                         </div>)
                         
}
                        
                        {/* userdetails_div start */}
                         
                          {/* userdetails_div end */}
                    </div>
                      </>
                    )
                  })}
                    
                </div>
                {/* col end */}

            </div>
            {/* row end */}
        </div>
      </section>
    </>
  )
}

export default Myprofile
