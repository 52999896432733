import React from 'react'
const Sidenavbar = () => {
  return (
    <>
    {/* side bar nav main div start */}
    <div className="sidebar_main_div">
  <div class="offcanvas offcanvas-start d-block d-lg-none" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="sidenavbar" aria-labelledby="sidenavbarLabel">
    {/* start header */}
 <div className="offcanvas-header"> 
  <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
    <a href="/login" data-bs-toggle="modal" data-bs-target="#login">
    <img src="../images/loginicon.png" alt /> SIGN IN/SIGN UP <i className='bx bx-log-in-circle'></i></a>
    </h5>
  <button type="button" className="fa fa-times" data-bs-dismiss="offcanvas" aria-label="Close" />
</div>
{/* end haeder */}

{/* offcanvas body start */}
    <div className="offcanvas-body">
     {/* sidebar navlink div start */}
     <div className="sidenav_navlink_div">
        <ul>
            <li><a href="/"> <i className='bx bx-home'></i> Home</a></li>
            <li><a href="/about"><i className='bx bx-book-reader'></i> Company Profile</a></li>
             {/*  All Categories li */}
             <div className="categories_div_main">
     <li><a href="javascript:void(0);"><i className='bx bx-category-alt'></i> 
        Categories </a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
         <li><a href="/chalk-making-machine"><i className='bx bx-cog'></i> Chalk Making Machine</a></li>
         <li><a href="/chalk-making-mold"><i className='bx bx-cog'></i> Chalk Making Mold </a></li>
         <li><a href=""><i className='bx bx-cog'></i> Semi Automatic Candle Machine </a></li>
         <li><a href=""><i className='bx bx-cog'></i> Fully Automatic Candle Machine</a></li>
         <li><a href=""><i className='bx bx-cog'></i> Candle Making Mold</a></li>
         <li><a href=""><i className='bx bx-cog'></i> Lakshman Chalk Mold</a></li>
      </ul>
    </div>
  </div>
  </div>
  {/* All Categories drop li end */}
            <li><a href="/contact"><i className='bx bxs-contact'></i> Contact Us</a></li>
        </ul>
     </div>
     {/* sidebar navlink div end*/}

{/* side bar profile link start  */}
<div className="sidebar_profile_div_main">
    <ul>
        <li><a href="/myprofile"><i className='bx bxs-user-detail'></i> My Profile</a></li>
        <li><a href="/orders"><i className='bx bxs-shopping-bags'></i> Orders</a></li>
    </ul>
</div>
{/* side bar profile link end */}

{/* side bar terms link start  */}
<div className="sidebar_terms_div_main">
    <ul>
        <li><a href="/terms-conditions"><i className='bx bx-file'></i> Terms & Conditions</a></li>
        <li><a href="/privacy-policy"><i className='bx bx-check-shield'></i> Privacy Policy</a></li>
        <li><a href="/refund-cancellation"><i className='bx bx-money'></i> Refund & Cancellation</a></li>
    </ul>
</div>
{/* side bar terms link end */}

    </div>
    {/* offcanvas body end */}

  </div>
  </div>
    {/* side bar nav main div end */}

    </>
  )
}

export default Sidenavbar
